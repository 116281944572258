<template>
  <VContainer fluid>
    <VForm
      ref="form"
      v-model="valid"
      @submit.prevent="submit"
    >
      <div v-show="registrationStep !== 2">
        <header>
          <img
            class="logo"
            :src="(vendor.branding && vendor.branding.logo) ? `/${vendor.branding.logo}` : logotype"
            :alt="vendor.title"
            aria-hidden="true"
          >
          <h1 class="page-title">
            {{ localDocumentTitle }}
          </h1>
        </header>

        <VSelect
          v-model="profileType"
          :items="profileTypes"
          outlined
          name="profile[type]"
        />

        <VTextField
          v-model="title.value"
          outlined
          persistent-hint
          :label="profileType === 'person' ? 'ФИО' : 'Название организации'"
          name="title"
          class="required"
          :rules="[
            v => !!v || 'Это поле обязательно',
            v => v.length <= 1023 || 'Логин должен быть не больше 1023 символов'
          ]"
          maxlength="1023"
          autocomplete="organization name"
        />

        <VTextField
          v-model="email.value"
          outlined
          persistent-hint
          :label="$t('anonymous_your_email.one')"
          name="email"
          class="required"
          :rules="[
            v => !!v || 'Это поле обязательно',
            v => v.length <= 254 || 'Логин должен быть не больше 254 символов',
            v => /.+@.+/.test(v) || 'Невалидный email'
          ]"
          type="email"
          maxlength="254"
          autocomplete="email"
        />

        <VTextField
          v-model="phone.value"
          v-mask="phoneMask"
          outlined
          persistent-hint
          :label="$t('phones.one')"
          type="tel"
          name="phone"
          class="required"
          :rules="[ v => !!v || 'Это поле обязательно', ]"
          maxlength="254"
          autocomplete="tel"
        />

        <VBtn
          v-if="vendor?.branding?.extended_registration_form"
          x-large
          block
          color="primary"
          class="mb-4"
          :loading="isSubmitting"
          :disabled="!valid"
          @click="updateStep(2)"
        >
          {{ $t('$app.next.one') }}
        </VBtn>

        <VBtn
          v-else
          x-large
          type="submit"
          block
          color="primary"
          :loading="isSubmitting"
        >
          {{ $t('anonymous_register.one') }}
        </VBtn>

        <template
          v-if="vendor?.branding?.extended_registration_form"
        >
          <VDivider />

          <footer class="text-center">
            <VCardText>
              Уже есть аккаунт? <RouterLink to="/login">
                {{ $t('anonymous_sign_in.one') }}
              </RouterLink>
            </VCardText>
          </footer>
        </template>

        <footer
          v-else
          class="text-center"
        >
          <VCardText>
            Уже есть аккаунт? <RouterLink to="/login">
              {{ $t('anonymous_sign_in.one') }}
            </RouterLink>
          </VCardText>
          <VDivider />
          <VCardText>
            Нажимая на кнопку «Зарегистрироваться», вы соглашаетесь с <RouterLink
              :to="{ name: 'privacy-policy' }"
              target="_blank"
            >
              политикой обработки персональных данных.
            </RouterLink>
          </VCardText>
        </footer>
      </div>

      <div
        v-if="vendor?.branding?.extended_registration_form && registrationStep === 2"
      >
        <h2 class="mb-2">
          Реквизиты {{ profileType === 'person' ? 'физического' : 'юридического' }} лица
        </h2>

        <p class="subtitle-1">
          Для окончания регистрации требуется заполнить поля
        </p>

        <template v-if="profileType === 'person'">
          <VTextField
            v-model="person.inn.value"
            :class="person.inn.required && 'required'"
            outlined
            persistent-hint
            :label="$t('inn.one')"
            name="profile[person][inn]"
            maxlength="12"
            :rules="[ v => preparedRule(person.inn.required, v) || /^([0-9]{10}([0-9]{2})?)$/.test(v), ]"
          />

          <VTextField
            v-model="person.snils.value"
            v-mask="'###-###-### ##'"
            :class="person.snils.required && 'required'"
            outlined
            persistent-hint
            :label="$t('snils.one')"
            name="profile[person][snils]"
            placeholder="XXX-XXX-XXX YY"
            :rules="[ v => preparedRule(person.snils.required, v) || validateSNILS, ]"
          />

          <VTextField
            v-model="person.dob.value"
            :class="person.dob.required && 'required'"
            outlined
            persistent-hint
            :label="$t('dob.one') + $t('form_filed_desc_free_form.one')"
            name="profile[person][dob]"
            :rules="[ v => preparedRule(person.dob.required, v) || !!(v || '').trim().length > 0 || 'Заполните поле', ]"
            maxlength="63"
          />

          <VTextField
            v-model="person.passport.value"
            :class="person.passport.required && 'required'"
            outlined
            persistent-hint
            :label="$t('user_profile_passport_info.one') + ': ' + $t('user_profile_passport_info_placeholder.one').toLowerCase()"
            name="profile[person][passport]"
            :rules="[ v => preparedRule(person.passport.required, v) || !!(v || '').trim().length > 0 || 'Заполните поле', ]"
            maxlength="254"
          />

          <VTextField
            v-model="person.locality.value"
            :class="person.locality.required && 'required'"
            outlined
            persistent-hint
            :label="$t('locality_of_a_person.one')"
            name="profile[person][locality]"
            :rules="[ v => preparedRule(person.locality.required, v) || !!(v || '').trim().length > 0 || 'Заполните поле', ]"
            maxlength="254"
          />

          <VTextField
            v-model="person.address.value"
            :class="person.address.required && 'required'"
            outlined
            persistent-hint
            :label="$t('address_of_a_person.one')"
            name="profile[person][address]"
            :rules="[ v => preparedRule(person.address.required, v) || !!(v || '').trim().length > 0 || 'Заполните поле', ]"
            maxlength="766"
          />

          <VTextField
            v-model="person.citizenship.value"
            :class="person.citizenship.required && 'required'"
            outlined
            persistent-hint
            label="Страна"
            value="Российская Федерация"
            name="profile[person][citizenship]"
            :rules="[ v => preparedRule(person.citizenship.required, v) || !!(v || '').trim().length > 0 || 'Заполните поле', ]"
            maxlength="254"
          />

          <VSelect
            v-model="person.gender.value"
            :class="person.gender.required && 'required'"
            :label="$t('gender.one')"
            :items="genders"
            :rules="[ v => preparedRule(person.gender.required, v) || !!v || 'Заполните поле', ]"
            outlined
            name="profile[person][gender]"
          />
        </template>

        <template v-else>
          <div>
            <VTextField
              v-model="company.title.value"
              outlined
              persistent-hint
              label="Полное наименование организации"
              name="profile[company][title]"
              maxlength="766"
            />

            <p
              v-if="companyLookupEnabled"
              class="warning--text"
            >
              Доступно автозаполнение реквизитов&nbsp;&mdash; вставьте ИНН и&nbsp;БИК
            </p>

            <VTextField
              v-model="company.inn.value"
              outlined
              persistent-hint
              :label="$t('inn.one')"
              name="profile[company][inn]"
              maxlength="12"
              :rules="[ v => preparedRule(company.inn.required, v) || /^([0-9]{10}([0-9]{2})?)$/.test(v), ]"
              :class="company.inn.required && 'required'"
              @input="autofillByInn"
            />

            <VTextField
              v-model="company.kpp.value"
              v-mask="'##-##-##-###'"
              outlined
              persistent-hint
              :label="$t('kpp.one')"
              name="profile[company][kpp]"
              :rules="[ v => preparedRule(company.kpp.required, v) || (v.replace(/-/g, '') || '').trim().length === 9 || 'В КПП должно быть 9 символов', ]"
              :class="company.kpp.required && 'required'"
              @input="autofillByKpp"
            />

            <VTextField
              v-model="company.ogrn.value"
              v-mask="'#############'"
              outlined
              persistent-hint
              :label="$t('ogrn.one')"
              name="profile[company][ogrn]"
              :rules="[ v => preparedRule(company.ogrn.required, v) || (v || '').trim().length === 13 || 'В ОГРН должно быть 13 символов', ]"
              :class="company.ogrn.required && 'required'"
            />

            <VTextField
              v-model="company.okpo.value"
              outlined
              persistent-hint
              :label="$t('okpo.one')"
              name="profile[company][okpo]"
              maxlength="10"
              :rules="[ v => preparedRule(company.okpo.required, v) || (v || '').trim().length >= 8 || 'В ОКПО должно быть не менее 8 символов', ]"
              :class="company.okpo.required && 'required'"
            />

            <VTextField
              v-model="company.oktmo.value"
              outlined
              persistent-hint
              :label="$t('oktmo.one')"
              name="profile[company][oktmo]"
              maxlength="16"
              :rules="[ v => preparedRule(company.oktmo.required, v) || (v || '').trim().length >= 8 || 'В ОКТМО должно быть не менее 8 символов', ]"
              :class="company.oktmo.required && 'required'"
            />

            <VTextField
              v-model="company.okved.value"
              outlined
              persistent-hint
              :label="$t('okved.one')"
              name="profile[company][okved]"
              maxlength="254"
              :rules="[ v => preparedRule(company.okved.required, v) || (v || '').trim().length > 0 || 'Заполните поле', ]"
              :class="company.okved.required && 'required'"
            />
          </div>

          <div>
            <h2>
              Банковские реквизиты
            </h2>

            <VTextField
              v-model="company.bank.name.value"
              outlined
              persistent-hint
              :label="$t('bank_name.one')"
              name="profile[bank][name]"
              maxlength="254"
              :rules="[ v => preparedRule(company.bank.name.required, v) || (v || '').trim().length > 0 || 'Заполните поле', ]"
              :class="company.bank.name.required && 'required'"
            />

            <VTextField
              v-model="company.bank.address.value"
              outlined
              persistent-hint
              :label="$t('bank_address.one')"
              name="profile[bank][address]"
              maxlength="766"
              :rules="[ v => preparedRule(company.bank.address.required, v) || (v || '').trim().length > 0 || 'Заполните поле', ]"
              :class="company.bank.address.required && 'required'"
            />

            <VTextField
              v-model="company.bank.bik.value"
              v-mask="'#########'"
              outlined
              persistent-hint
              :label="$t('bik.one')"
              name="profile[bank][bik]"
              maxlength="9"
              :rules="[ v => preparedRule(company.bank.bik.required, v) || (v || '').trim().length === 9 || 'В БИК должно быть 9 символов', ]"
              :class="company.bank.bik.required && 'required'"
              @input="autofillByBik"
            />

            <VTextField
              v-model="company.bank.rs.value"
              outlined
              persistent-hint
              :label="$t('rs.one')"
              name="profile[bank][rs]"
              maxlength="44"
              :rules="[ v => preparedRule(company.bank.rs.required, v) || (v || '').trim().length >= 20 || 'В рассчетном счете должно быть не менее 20 символов', ]"
              :class="company.bank.rs.required && 'required'"
            />

            <VTextField
              v-model="company.bank.ks.value"
              outlined
              persistent-hint
              :label="$t('ks.one')"
              name="profile[bank][ks]"
              maxlength="44"
              :rules="[ v => preparedRule(company.bank.ks.required, v) || (v || '').trim().length >= 20 || 'В корреспондентском счёте должно быть не менее 20 символов', ]"
              :class="company.bank.ks.required && 'required'"
            />

            <VTextField
              v-model="company.bank.ls.value"
              outlined
              persistent-hint
              :label="$t('ls.one')"
              name="profile[bank][ls]"
              maxlength="127"
              :rules="[ v => preparedRule(company.bank.ls.required, v) || (v || '').trim().length > 0 || 'Заполните поле', ]"
              :class="company.bank.ls.required && 'required'"
            />
          </div>

          <div>
            <h2>
              Сведения о юридическом лице
            </h2>

            <VTextField
              v-model="company.locality.value"
              outlined
              persistent-hint
              :label="$t('locality.one')"
              name="profile[company][locality]"
              maxlength="254"
              :rules="[ v => preparedRule(company.locality.required, v) || (v || '').trim().length > 0 || 'Заполните поле', ]"
              :class="company.locality.required && 'required'"
            />

            <VTextField
              v-model="company.address.value"
              outlined
              persistent-hint
              :label="$t('address.one')"
              name="profile[company][address]"
              maxlength="766"
              :rules="[ v => preparedRule(company.address.required, v) || (v || '').trim().length > 0 || 'Заполните поле', ]"
              :class="company.address.required && 'required'"
            />

            <VTextField
              v-model="company.director.value"
              outlined
              persistent-hint
              :label="$t('director.one') + ' (' + $t('full_name_person.one') + ', ' + $t('user_profile_position.one').toLowerCase() + ')'"
              name="profile[company][director]"
              maxlength="254"
              :rules="[ v => preparedRule(company.director.required, v) || (v || '').trim().length > 0 || 'Заполните поле', ]"
              :class="company.director.required && 'required'"
            />
          </div>
        </template>

        <VBtn
          x-large
          type="submit"
          block
          color="primary"
          class="mb-4"
          :disabled="!valid"
          :loading="isSubmitting"
        >
          {{ $t('anonymous_register.one') }}
        </VBtn>

        <VBtn
          x-large
          block
          outlined
          color="primary"
          class="mb-2"
          :loading="isSubmitting"
          @click="updateStep(1)"
        >
          {{ $t('back.one') }}
        </VBtn>

        <VDivider />

        <footer class="text-center">
          <VCardText>
            Нажимая на кнопку «Зарегистрироваться», вы соглашаетесь с <RouterLink
              :to="{ name: 'privacy-policy' }"
              target="_blank"
            >
              политикой обработки персональных данных.
            </RouterLink>
          </VCardText>
        </footer>
      </div>
    </VForm>
  </VContainer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mask } from 'vue-the-mask'

import documentTitle from '../mixins/documentTitle'
import logotype from '../assets/images/uchi.svg'
import * as actions from '@/store/actions/types'
import * as getters from '@/store/getters/types'
import { validateSNILS } from '../utils/snilsValidator'
import { getLookupProfileCompany, getLookupProfileBank } from '@/api/api'

export default {
  name: 'TheAccountRegister',
  directives: { mask },
  mixins: [documentTitle],

  data () {
    return {
      documentTitle: 'anonymous_register.one',
      valid: false,
      title: { value: '' },
      email: { value: '' },
      phone: { value: '' },
      person: {
        inn: { value: '' },
        snils: { value: '' },
        dob: { value: '' },
        passport: { value: '' },
        locality: { value: '' },
        address: { value: '' },
        citizenship: { value: '' },
        gender: { value: '' },
      },
      company: {
        title: { value: '' },
        inn: { value: '' },
        kpp: { value: '' },
        ogrn: { value: '' },
        okpo: { value: '' },
        oktmo: { value: '' },
        okved: { value: '' },
        locality: { value: '' },
        address: { value: '' },
        director: { value: '' },
        bank: {
          name: { value: '' },
          address: { value: '' },
          bik: { value: '' },
          rs: { value: '' },
          ks: { value: '' },
          ls: { value: '' },
        }
      },
      phoneMask: '+###############',
      logotype,
      isSubmitting: false,
      profileType: '',
      profileTypes: [
        { text: 'Физическое лицо', value: 'person' },
        { text: 'Юридическое лицо', value: 'company' },
      ],
      genders: [
        { text: 'Не указано', value: '' },
        { text: this.$t('gender_male.one'), value: 'm' },
        { text: this.$t('gender_female.one'), value: 'f' },
      ],
      registrationStep: 1,
      validateSNILS,
      profiles: [],
    }
  },

  computed: {
    ...mapGetters({
      vendor: getters.VENDOR,
    }),

    companyLookupEnabled () {
      return this.vendor?.branding?.company_lookup_enabled || false
    },

    requiredContractorProfileFields () {
      return this.vendor?.branding?.required_contractor_profile_fields || []
    },
  },

  created () {
    this.profileType = this.vendor?.branding?.default_contractor_type || 'person'

    this.requiredContractorProfileFields.forEach(field => {
      switch (field) {
        case 'bank_name':
          this.company.bank.name.required = true
          break
        case 'bank_address':
          this.company.bank.address.required = true
          break
        default:
          this.person[field] && (this.person[field].required = true)
          this.company[field] && (this.company[field].required = true)
          this.company.bank[field] && (this.company.bank[field].required = true)
          break
      }
    })
  },

  methods: {
    ...mapActions({
      registerAccount: actions.REGISTER,
      createNotification: actions.CREATE_NOTIFICATION
    }),

    preparedRule (required, value) {
      const length = (value || '').trim().length
      return !required && !length
    },

    async submit () {
      const form = this.$refs.form

      if (!form.validate()) {
        this.registrationStep = 1
        this.createNotification({
          msg: this.$t('$app.all_fields_required'),
          type: 'error'
        })

        return
      }
      this.isSubmitting = true

      this.registerAccount(new FormData(form.$el))
      this.isSubmitting = false
    },

    updateStep (value) {
      this.registrationStep = value
    },

    populateProfileFields (profile) {
      Object.keys(profile || {}).forEach(key => {
        switch (key) {
          case 'inn':
          case 'kpp':
            // Поля ИНН и КПП не перезаписываем.
            break
          case 'city':
            this.company.locality = profile[key]
            break
          default:
            if (this.company[key]) {
              this.company[key].value = profile[key]
            }
        }
      })
    },

    async autofillByInn (value) {
      if (!this.companyLookupEnabled) {
        return
      }

      value = value.trim()

      // При вводе 10 и более символов ИНН отправим запрос на сервер.
      if (value.length >= 10) {
        const data = new FormData()
        data.append('inn', value)
        data.append('kpp', (this.company.kpp.value || '').replace(/-/g, ''))

        const response = await getLookupProfileCompany(data)
        if (response.profiles.length) {
          response.profiles.forEach(profile => {
            this.profiles[profile.inn + '/' + profile.kpp] = profile
          })

          // Разложим по полям формы первый из найденных профилей.
          const profile = response.profiles[0]
          this.populateProfileFields(profile)
          if (this.company.kpp.value === '') {
            // Заполним пустое поле КПП.
            this.company.kpp.value = profile.kpp
          }

          if (response.profiles.length > 1) {
            this.createNotification({
              msg: 'По указанным параметрам найдено более одной организации, попробуйте уточнить запрос вводом КПП.',
              type: 'warning'
            })
          }
        }
      }
    },

    async autofillByKpp (value) {
      if (!this.companyLookupEnabled) {
        return
      }

      value = value.replace(/-/g, '').trim()

      // При вводе 9 и более символов КПП отправим запрос на сервер.
      if (value.length >= 9 && this.company.inn.value) {
        const profile = this.profiles[this.company.inn.value + '/' + value]
        if (profile) {
          this.populateProfileFields(profile)
          return
        }

        const data = new FormData()
        data.append('inn', value)
        data.append('kpp', this.company.kpp.value)

        const response = await getLookupProfileCompany(data)
        if (response.profiles.length) {
          response.profiles.forEach(profile => {
            this.profiles[profile.inn.value + '/' + profile.kpp.value] = profile
          })
          // Разложим по полям формы первый из найденных профилей.
          const profile = response.profiles[0]
          this.populateProfileFields(profile)
        }
      }
    },

    async autofillByBik (value) {
      if (!this.companyLookupEnabled) {
        return
      }

      // При вводе 8 и более символов БИК отправим запрос на сервер.
      if (value.length >= 8) {
        const data = new FormData()
        data.append('bik', value)

        const response = await getLookupProfileBank(data)
        const bank = response?.bank
        if (bank?.bik) {
          Object.keys(bank).forEach(key => {
            if (key !== 'bik') {
              this.company.bank[key].value = bank[key]
            }
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  h2 {
    margin: 0 0 $spacer*6 0
  }
</style>
